<template>
  <header class="header">
    <a-form class="form" layout="inline" autocomplete="off" :model="query" @finish="onFinish">
      <a-form-item
        class="form-item"
        v-for="(formConfig, index) in formConfigs"
        :key="createKey(formConfig.key)"
        :label="formConfig.label"
        :name="createKey(formConfig.key)"
      >
        <template v-if="formConfig.type === 'input'">
          <template v-if="!formConfig.disabled">
            <a-input
              class="input"
              :value="getObjectValue(query, formConfig.key)"
              :placeholder="`请输入${formConfig.label}`"
              @change="(e) => onChangeValue(formConfig.key, e.target.value)"
              allowClear
            />
          </template>
          <template v-else>
            <a-input class="input" :value="formConfig.disabledValue" :disabled="formConfig.disabled" />
          </template>
        </template>
        <template v-else-if="formConfig.type === 'select'">
          <a-select
            class="select"
            :value="getObjectValue(query, formConfig.key) ?? null"
            :placeholder="`请选择${formConfig.label}`"
            :options="createSelectOptions(formConfig.options, formConfig.selectConfig)"
            @change="(val) => onChangeValue(formConfig.key, val)"
            allowClear
          />
        </template>
        <template v-else-if="formConfig.type === 'datePicker'">
          <a-date-picker
            class="date-picker"
            :value="getObjectValue(query, formConfig.key) ?? null"
            :format="formConfig.format || 'YYYY-MM-DD'"
            :value-format="formConfig.valueFormat || formConfig.format || 'YYYY-MM-DD'"
            @change="(val, valString) => onChangeValue(formConfig.key, valString)"
          />
        </template>
        <!-- 注意，这个组件很特殊 -->
        <template v-else-if="formConfig.type === 'rangePicker'">
          <a-range-picker
            class="range-picker"
            :value="getRangePickerMomentTime(query, formConfig.key)"
            :showTime="formConfig.showTime"
            :format="formConfig.format || 'YYYY-MM-DD'"
            :value-format="formConfig.valueFormat || formConfig.format || 'YYYY-MM-DD'"
            @change="(vals, valStrings) => onChangeRangePickerTimeValue(formConfig.key, valStrings)"
            :placeholder="formConfig.placeholder"
          />
        </template>
        <template v-else-if="formConfig.type === 'rangeNumber'">
          <a-input-group compact>
            <a-input-number
              :min="0"
              class="input-start"
              placeholder="最小值"
              :value="getRangeNumberStartValue(query, formConfig.key)"
              @change="(val) => onChangeRangeNumberStartValue(formConfig.key, val)"
            />
            <a-input class="input-middle" placeholder="~" value="~" readonly />
            <a-input-number
              class="input-end"
              placeholder="最大值"
              :value="getRangeNumberEndValue(query, formConfig.key)"
              :min="1"
              @change="(val, valStrings) => onChangeRangeNumberEndValue(formConfig.key, val)"
            />
          </a-input-group>
        </template>
        <template v-else-if="formConfig.type === 'cascader'">
          <a-cascader
            :value="getCascader(query, formConfig.key)"
            :options="createTree(formConfig.options)"
            @change="(val) => onChangeCascaderValue(formConfig.key, val)"
            :placeholder="`请选择${formConfig.label}`"
            :multiple="formConfig.multiple || true"
          />
        </template>
        <template v-else-if="formConfig.type === 'region'">
          <BusinessAreaCascader
            :areaValues="getAddressValues(query, formConfig.key[0])"
            @update:areaValues="onUpdateAreaValues"
            :areakey="formConfig.key"
          />
        </template>

        <template v-else-if="formConfig.type === 'custom'">
          <slot :name="formConfig.key" :formConfig="formConfig" :formData="query" :index="index" />
        </template>
      </a-form-item>
      <a-form-item class="form-item">
        <template v-if="showDefaultButton">
          <a-button class="btn" type="primary" :loading="loading" html-type="submit">搜索</a-button>
          <a-button class="btn" @click="onClickReset">重置</a-button>
        </template>

        <slot name="formExtends" />
      </a-form-item>
    </a-form>
  </header>
  <slot name="tableMarks" />
  <section class="body">
    <a-table
      size="small"
      :rowKey="tableRowKey"
      :dataSource="dataSource"
      :pagination="{
        total: tableTotal,
        current: queryPagination.pageNo,
        pageSize: queryPagination.pageSize,
        showSizeChanger: true,
        showQuickJumper: true,
        size: 'middle',
        showTotal: showTotal
      }"
      :loading="loading"
      :bordered="true"
      :scroll="scrollConfigs"
      @change="onChangeTablePagination"
    >
      <template v-for="column in tableColumns" :key="column.dataIndex">
        <a-table-column
          v-if="column.type !== 'custom'"
          :key="column.dataIndex"
          :title="column.title"
          :dataIndex="column.dataIndex"
          :width="column.width"
          :customRender="(item) => customRender(item, column)"
        />
        <a-table-column
          v-else
          :title="column.title"
          :dataIndex="column.dataIndex"
          :width="column.width"
          :fixed="column.fixed || false"
          :align="column.align || 'left'"
        >
          <template #default="{ record }">
            <slot :name="column.dataIndex" :column="column" :record="record" />
          </template>
        </a-table-column>
      </template>
    </a-table>
  </section>
</template>

<script>
import { ref } from 'vue';
import * as moment from 'moment';
import { cloneDeep, set, get } from 'lodash';
import { useRequest } from 'ahooks-vue';
// apis
// utils
import { getSelectLabelByValue } from '@src/utils';
import {
  createQuery,
  createKey,
  createSelectOptions,
  createTree,
  createRequestParams,
  getObjectValue,
  getRangePickerMomentTime,
  getRangeNumberStartValue,
  getRangeNumberEndValue,
  getCascader
} from './utils';
import { message } from 'ant-design-vue';
import { BOOLEAN_BINARY_OPERATORS } from '@babel/types';
import { BusinessAreaCascader } from '@src/components';
export default {
  name: 'ZgyxSearchTablePagination',
  emits: ['successCallBack'],
  components: { BusinessAreaCascader },
  props: {
    extendFormData: { type: Object }, // 额外的请求参数
    formConfigs: { type: Array, default: () => [] }, // form表单配置
    tableRowKey: { type: String }, // table的rowKey
    tableColumns: { type: Array, default: () => [] },
    requestFunction: { type: Function },
    baseFormData: { type: Object }, // 基础请求参数
    initFlag: { type: Boolean, default: () => true },
    showDefaultButton: { type: Boolean, default: () => true },
    scrollConfigs: { type: Object } // 滚动参数
  },
  setup(props, { emit }) {
    if (process.env.NODE_ENV === 'development') {
    }

    const query = ref(createQuery(props.formConfigs)); // form表单
    const queryPagination = ref({ pageNo: 1, pageSize: 10 }); // 翻页参数
    const dataSource = ref([]);
    const tableTotal = ref(0);

    const { loading, run: getList } = useRequest(
      async () => {
        judgeRange();
        const _query = createRequestParams(props.formConfigs, query.value);
        const params = Object.assign({}, _query, queryPagination.value, props.extendFormData);
        return await props.requestFunction(params);
      },
      {
        manual: true,
        loadingDelay: 300,
        debounceInterval: 300,
        onSuccess: ({ code, data: { list = [], total } }) => {
          if (code === '00000' && total) {
            list.map((e, index) => {
              //增加默认排序
              e.mySort = index + 1;
            });
            tableTotal.value = total ?? 0;
            dataSource.value = list ?? [];
          } else {
            tableTotal.value = 0;
            dataSource.value = [];
          }
          const _query = createRequestParams(props.formConfigs, query.value);
          const params = Object.assign({}, _query, queryPagination.value, props.extendFormData);
          emit('successCallBack', params);
        }
      }
    );
    /**
     * 修改form
     * @param {*} key
     * @param {*} val
     */
    const onChangeValue = (key, val) => {
      try {
        const nextQuery = cloneDeep(query.value);
        set(nextQuery, key, val);
        query.value = nextQuery;
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 修改rangePicker
     * @param {*} param0
     * @param {*} param1
     */
    const onChangeRangePickerTimeValue = ([key1, key2], [valStr1, valStr2]) => {
      try {
        const nextQuery = cloneDeep(query.value);
        set(nextQuery, key1, valStr1);
        set(nextQuery, key2, valStr2);
        query.value = nextQuery;
      } catch (error) {
        console.error(error);
      }
    };
    const onChangeRangeNumberStartValue = ([key1, key2], valStr) => {
      try {
        const nextQuery = cloneDeep(query.value);
        set(nextQuery, key1, valStr);
        query.value = nextQuery;
      } catch (error) {
        console.error(error);
      }
    };
    const onChangeCascaderValue = (key, valStr) => {
      try {
        const nextQuery = cloneDeep(query.value);

        key.map((item, index) => {
          set(nextQuery, item, valStr[index]);
        });

        query.value = nextQuery;
      } catch (error) {
        console.error(error);
      }
    };

    const onChangeRangeNumberEndValue = ([key1, key2], valStr) => {
      try {
        const nextQuery = cloneDeep(query.value);
        set(nextQuery, key2, valStr);
        query.value = nextQuery;
        // message
      } catch (error) {
        console.error(error);
      }
    };
    const judgeRange = () => {
      props.formConfigs.forEach((config) => {
        const { key, type, label } = config;
        if (type === 'rangeNumber') {
          const [key1, key2] = key;
          if (get(query.value, key2) && get(query.value, key1)) {
            if (get(query.value, key2) < get(query.value, key1)) {
              const nextQuery = cloneDeep(query.value);
              set(nextQuery, key2, get(query.value, key1));
              query.value = nextQuery;
            }
          }
        }
      });
    };
    /**
     * 翻页
     */
    const onChangeTablePagination = (pagination) => {
      try {
        const { current, pageSize } = pagination;
        queryPagination.value = { pageNo: current, pageSize: pageSize };
        getList();
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 表单录入
     */
    const onFinish = () => {
      try {
        queryPagination.value = { pageNo: 1, pageSize: 10 };
        getList();
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 点击重置
     */
    const onClickReset = () => {
      try {
        query.value = createQuery(props.formConfigs);
        queryPagination.value = { pageNo: 1, pageSize: 10 };
      } catch (error) {
        console.error(error);
      }
    };

    const customRender = ({ text, record }, column) => {
      try {
        const { type, options = [], selectConfig } = column;
        switch (type) {
          case 'select':
            return getSelectLabelByValue(options, text, selectConfig);
          case 'datePicker':
            return text ? moment(text).format(column.format ?? 'YYYY-MM-DD HH:mm:ss') : '';
          case 'input':
          default:
            return text;
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 更新区域的值
     */
    const onUpdateAreaValues = (value, key) => {
      try {
        const length = value.length;
        const nextQuery = cloneDeep(query.value);
        set(nextQuery, key[0], value);
        set(nextQuery, key[1], value[length - 1]);
        query.value = nextQuery;
      } catch (error) {
        console.error(error);
      }
    };
    const getAddressValues = (formData, key) => {
      return formData[key] || [];
    };

    const showTotal = (total) => {
      return `共 ${total} 条 `;
    };
    return {
      query,
      queryPagination,
      loading,
      dataSource,
      tableTotal,
      showTotal,
      createKey,
      createSelectOptions,
      createTree,
      getObjectValue,
      getRangePickerMomentTime,
      getRangeNumberStartValue,
      getRangeNumberEndValue,
      getCascader,

      getList, // 获取列表
      getAddressValues,
      onUpdateAreaValues,
      onChangeValue,
      onChangeRangePickerTimeValue,
      onChangeRangeNumberStartValue,
      onChangeRangeNumberEndValue,
      onChangeCascaderValue,

      onChangeTablePagination, // 翻页

      onFinish, // form的finish
      onClickReset, // 点击重置
      customRender // 个性化渲染
    };
  },
  computed: {},
  methods: {},
  mounted() {
    const _this = this;
    _this.$nextTick(() => {
      _this.query = Object.assign({}, _this.query, _this.baseFormData);
      if (_this.initFlag) {
        _this.getList();
      }
    });
  }
};
</script>

<style lang="less" scoped>
@import url('./ZgyxSearchTablePagination.less');
</style>
