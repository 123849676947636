import 'nprogress/nprogress.css';
import '@src/styles/reset/index.less';
import 'ant-design-vue/dist/antd.css';
import 'moment/locale/zh-cn';
import '@src/assets/pc.js';
import moment from 'moment';
import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faUserSecret,
  faTable,
  faFolder,
  faFile,
  faUser,
  faUsers,
  faUserTag,
  faCogs,
  faUserFriends,
  faUserTie,
  faStore,
  faEye,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faCoins,
  faBullhorn,
  faPaperPlane,
  faPaste,
  faFileAlt,
  faDatabase,
  faCog,
  faList,
  faShieldAlt,
  faFileCode,
  faListAlt,
  faAd,
  faBook,
  faScroll,
  faSmile,
  faHome,
  faClipboard,
  faBalanceScale
} from '@fortawesome/free-solid-svg-icons';

import App from './App.vue';

import router from '@src/router';
import store from '@src/store';

moment.locale('zh-cn');
library.add(
  faUserSecret,
  faTable,
  faFolder,
  faFile,
  faUser,
  faUserTag,
  faCogs,
  faUserFriends,
  faUserTie,
  faUsers,
  faStore,
  faEye,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faCoins,
  faBullhorn,
  faPaperPlane,
  faPaste,
  faFileAlt,
  faDatabase,
  faCog,
  faList,
  faShieldAlt,
  faFileCode,
  faListAlt,
  faAd,
  faBook,
  faScroll,
  faSmile,
  faHome,
  faClipboard,
  faBalanceScale
);

router.beforeEach((to, from, next) => {
  const { token } = store.getters;
  const { path } = to;
  if (!token && path !== '/login') {
    next({ path: '/login' });
  } else {
    next();
  }
});

const app = createApp(App);
app.directive('preReClick', {
  //防抖 v-preReClick
  mounted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 1000);
      }
    });
  }
});
app.use(router).use(store).use(Antd).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
