/*
 * @Author: wdy
 * @Date: 2022-03-03 10:14:47
 * @Last Modified by: lf
 * @Last Modified time: 2022-10-08 10:04:55
 */

// const createAreaTree = areas => {
//   return areas.map(area => {
//     const {regionName, regionId, children} = area;

//     return {
//       ...area,
//       label: regionName,
//       value: regionId,
//       children: children?.length ? createAreaTree(children) : null,
//     };
//   });
// };

const createAreaTree = (areas) => {
  return areas.map((area) => {
    const { regionName, regionCode, isLeaf } = area;

    return {
      // ...area,
      label: regionName,
      value: regionCode,
      isLeaf: isLeaf ? isLeaf : false
    };
  });
};

export { createAreaTree };
