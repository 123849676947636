import { isEmpty } from 'lodash';
// apis
// utils
// stores
// configs
// components
const checkMenu = (menuConfigs, userinfo) => {
  const { regionCode, roles = [] } = userinfo;
  let scopeType = '';
  if (regionCode) {
    const len = regionCode.length;
    switch (len) {
      case 1:
        scopeType = 'zg';
        break;
      case 2:
        scopeType = 'province';
        break;
      case 4:
        scopeType = 'city';
        break;
      case 6:
        scopeType = 'county';
        break;
      case 9:
        scopeType = 'town';
        break;
      case 12:
        scopeType = 'village';
        break;
      default:
        break;
    }
  }
  console.log('scopeType', scopeType);
  let newMenuConfigs = [];
  menuConfigs.map((e) => {
    if (e.meta.auth) {
      let a = new Set(e.meta.auth);
      let b = new Set(roles);
      let intersection = new Set([...a].filter((x) => b.has(x))); //2个数组的交集
      if (intersection.size) {
        //存在交集（用户有当前菜单权限）
        if (e.meta.scopeAuth) {
          if (e.meta.scopeAuth.includes(scopeType)) {
            //有省市权限（用户有当前菜单权限）
            newMenuConfigs.push(e);
          }
        } else {
          //无省市权限，则不校验权限直接输出
          newMenuConfigs.push(e);
        }
      }
    } else {
      //无角色权限，则不校验权限直接输出
      if (e.meta.scopeAuth) {
        if (e.meta.scopeAuth.includes(scopeType)) {
          //有省市权限（用户有当前菜单权限）
          newMenuConfigs.push(e);
        }
      } else {
        //无省市权限，则不校验权限直接输出
        newMenuConfigs.push(e);
      }
    }
  });
  return newMenuConfigs;
};

export { checkMenu };
