<template>
  <a-layout class="layout-wrap">
    <a-layout-sider class="layout-sider" v-model:collapsed="collapsed" collapsible collapsedWidth="40" width="140">
      <header class="logo-content">
        <!-- <img class="logo-old" v-if="!collapsed" src="../../assets/logo_old.png" alt="" srcset="" />
        <img class="logo-old-small" v-else src="../../assets/logo_old_small.png" alt="" srcset="" /> -->
      </header>
      <section class="menu-content">
        <a-menu theme="light" mode="inline" :selectedKeys="[activeMenu.path]" @click="onClickMenu">
          <Menus :menuConfigs="menuConfigs" />
        </a-menu>
      </section>
    </a-layout-sider>
    <a-layout class="layout-content">
      <header class="layout-header">
        <div class="title">浙江省老年友好型社区创建信息上报系统</div>
        <aside class="userinfo-content">
          <div class="left-border"></div>
          <a-dropdown :trigger="['hover']">
            <section class="userinfo" @click.prevent>
              <a-avatar class="avatar" :src="avatarImageUrl"> </a-avatar>
              <span class="user-name">{{ userinfo.username }}</span>
              <span class="arrow"> <DownOutlined /></span>
            </section>
            <template #overlay>
              <a-menu @click="onClickUserInfoMenu">
                <a-menu-item key="info" v-if="roles == 'tenant' && scopeType == 'village'">
                  <span>账号信息</span>
                </a-menu-item>
                <a-menu-item key="user" v-if="scopeType != 'village' && roles != 'admin'">
                  <span>个人信息</span>
                </a-menu-item>
                <!-- <a-menu-item key="changePassword" v-if="roles != 'admin'"> -->
                <a-menu-item key="changePassword">
                  <span>修改密码</span>
                </a-menu-item>
                <a-menu-item key="signOut">
                  <span>退出</span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </aside>
      </header>

      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
    </a-layout>

    <Info v-if="visibleModal" @onOk="onOkModal" @onCancel="onCancelModal" />
    <User v-if="visibleModalByUser" @onOk="onOkModalByUser" @onCancel="onCancelModalByUser" />
    <AccountChangePassword
      v-if="visibleForChangePassword"
      :accountId="accountIdForChangePassword"
      @onOk="onOkChangePasswordModal"
      @onCancel="onCancelChangePasswordModal"
    />
  </a-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { message, Modal } from 'ant-design-vue';
import { DownOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
import { checkMenu } from '@src/utils';
import { menuConfigs } from '@src/router/routers';
import Menus from './components/menus/Menus.vue';

import Info from './components/info';
import User from './components/user';
import AccountChangePassword from './components/password/AccountChangePassword.vue'; //重置密码
export default {
  name: 'Root',
  data() {
    return {
      collapsed: true,
      visibleModal: false,
      visibleModalByUser: false,
      visibleForChangePassword: false,
      accountIdForChangePassword: ''
    };
  },
  components: {
    DownOutlined,
    Menus,
    MenuUnfoldOutlined,
    Info,
    User,
    AccountChangePassword
  },
  computed: {
    ...mapGetters(['activeMenu', 'userinfo']),
    roles() {
      return this.userinfo.roles[0];
    },
    scopeType() {
      let regionCode = this.userinfo.regionCode;

      let scopeType = '';
      if (regionCode) {
        const len = regionCode.length;
        switch (len) {
          case 1:
            scopeType = 'zg';
            break;
          case 2:
            scopeType = 'province';
            break;
          case 4:
            scopeType = 'city';
            break;
          case 6:
            scopeType = 'county';
            break;
          case 9:
            scopeType = 'town';
            break;
          case 12:
            scopeType = 'village';
            break;
          default:
            scopeType = 'unknown';
            break;
        }
      }
      return scopeType;
    },
    /**
     * 菜单
     */
    menuConfigs() {
      const _this = this;
      const { userinfo } = _this;
      let menuConfigsList = menuConfigs.map((item) => {
        if (item.path == 'subAccount') {
          if (this.userinfo.accountGrade == 'main') {
            item.meta.hideInMenu = false;
          } else {
            item.meta.hideInMenu = true;
          }
        }
        return {
          ...item
        };
      });
      return checkMenu(menuConfigs, userinfo);
    },
    /*
     *  头像
     */
    avatarImageUrl() {
      return require('../../assets/avatar_default.png');
    }
  },
  methods: {
    ...mapActions(['setActiveMenu']),
    /**
     * 确定回调
     */
    onOkModalByUser() {
      try {
        const _this = this;
        _this.visibleModalByUser = false;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 取消回调
     */
    onCancelModalByUser() {
      try {
        const _this = this;
        _this.visibleModalByUser = false;
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 修改密码显示
     * @param {*} accountId
     */
    onClickChangePassword() {
      try {
        const _this = this;
        _this.accountIdForChangePassword = _this.userinfo.id;
        _this.visibleForChangePassword = true;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 修改密码确定回调
     */
    onOkChangePasswordModal() {
      try {
        const _this = this;
        _this.visibleForChangePassword = false;
        message.success({
          content: '密码修改成功,即将退出系统!',
          onClose: () => _this.$router.push({ path: `/login` })
        });
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 修改密码取消回调
     */
    onCancelChangePasswordModal() {
      try {
        const _this = this;
        _this.visibleForChangePassword = false;
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 确定回调
     */
    onOkModal() {
      try {
        const _this = this;
        _this.visibleModal = false;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 取消回调
     */
    onCancelModal() {
      try {
        const _this = this;
        _this.visibleModal = false;
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击用户菜单
     */
    onClickUserInfoMenu({ key }) {
      try {
        const _this = this;
        switch (key) {
          case 'info':
            _this.visibleModal = true;
            break;
          case 'user':
            _this.visibleModalByUser = true;
            break;

          case 'changePassword':
            _this.onClickChangePassword();
            break;
          case 'signOut':
            Modal.confirm({
              title: '信息',
              content: '确认退出系统？',
              cancelText: '取消',
              okText: '退出',
              onOk: () => _this.$router.push({ path: `/login` })
            });
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击左侧菜单
     * @param {*}
     */
    onClickMenu(e) {
      try {
        const _this = this;
        let { key, item } = e;
        const { title } = item;
        if (key.indexOf('/') !== 0) {
          key = '/' + key;
        }
        _this.setActiveMenu({ path: key, name: title });
      } catch (error) {
        console.error(error);
      }
    }
  },
  async mounted() {
    const _this = this;
  }
};
</script>

<style lang="less" scoped>
@import './Root.less';
</style>
<style lang="less">
.layout-wrap {
  .ant-layout-sider-trigger {
    background: transparent !important;
  }
}
.menu-content {
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ant-menu {
    color: #fff !important;
    background: transparent !important;
  }
  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    display: none;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }
}
</style>
