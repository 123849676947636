<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';

export default {
  name: 'App',
  computed: {
    locale() {
      return zhCN;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  max-width: 200px;
  white-space: normal;
}
.ant-tabs .ant-tabs-left-content {
  padding-left: 0 !important;
}
.form-outline .ant-form-item {
  width: 100%;
  margin-top: 24px;
}
.form-outline .ant-descriptions-item-content {
  padding: 0 24px !important;
}

.table-head {
  position: relative;
  width: 100%;
  height: 40px;
}
</style>
