<template>
  <a-upload
    action="/upload/other/upload"
    :headers="{ Authorization: `Bearer ${token}` }"
    :show-upload-list="false"
    :with-credentials="true"
    :before-upload="onBeforeUpload"
    :disabled="disabled"
    @change="onUploadChange"
  >
    <div v-if="visibleDefaultUpload">
      <slot />
      <CloseCircleOutlined class="icon" @click.stop="onClickDelete" v-if="!disabled" />
    </div>
    <template v-if="visibleUpload">
      <slot name="empty" v-if="visibelEmptySlot" />
      <a-button v-else>点击上传PDF</a-button>
    </template>
  </a-upload>
</template>

<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { isArray, isNumber } from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons-vue';
// apis
// utils
// mixins
// configs
// components
export default {
  name: 'ZgyxUploadFiles',
  components: { CloseCircleOutlined },
  props: {
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 最大数组
    maxCount: {
      type: Number,
      default: 1
    },
    // 远程文件地址
    remoteFileIds: {
      type: [String, Number],
      default: ''
    },
    fileTypes: {
      type: Array,
      default() {
        return ['application/pdf'];
      }
    }
  },
  computed: {
    ...mapGetters(['token']),
    /**
     * 本地的ids
     */
    locationFileIds() {
      const { remoteFileIds } = this;
      if (isNumber(remoteFileIds)) {
        return [`${remoteFileIds}`];
      }
      return remoteFileIds ? remoteFileIds.split(',') : [];
    },
    /**
     * 是否显示上传组件
     */
    visibleUpload() {
      const _this = this;
      const { locationFileIds, maxCount } = _this;
      return locationFileIds.length < maxCount;
    },
    /**
     * 是否存在图片
     */
    visibleDefaultUpload() {
      const _this = this;
      return Boolean(_this.$slots.default && _this.remoteFileIds);
    },
    /**
     * 是否使用外部传入的empty组件
     */
    visibelEmptySlot() {
      const _this = this;
      const visibelEmptySlot = Boolean(_this.$slots.empty);
      return visibelEmptySlot;
    }
  },
  setup(props, { emit }) {
    const onBeforeUpload = (file) => {
      try {
        const isOk = props.fileTypes.includes(file.type);
        if (!isOk) {
          message.error('文件类型不符合要求！');
        }
        return isOk;
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 修改回调
     */
    const onUploadChange = ({ file }) => {
      try {
        const { remoteFileIds } = props;
        const { status, response } = file;
        if (status === 'done') {
          const {
            code,
            data: { fileId }
          } = response;
          if (code === '00000') {
            if (isArray(remoteFileIds)) {
              emit('update:remoteFileIds', remoteFileIds.concat([fileId])).join();
            } else {
              emit('update:remoteFileIds', fileId);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    const onClickDelete = () => {
      try {
        emit('update:remoteFileIds', '');
      } catch (error) {
        console.error(error);
      }
    };
    return {
      onBeforeUpload,
      onUploadChange,
      onClickDelete
    };
  }
};
</script>

<style lang="less" scoped>
@import url('./ZgyxUploadFiles.less');
</style>
