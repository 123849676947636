<template>
  <a-config-provider :locale="zhCN">
    <section class="layout">
      <section class="content">
        <slot />
      </section>
    </section>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  name: 'ZgyxLayout',
  props: {
    pageName: String,
    breadcrumbs: Array
  },
  computed: {
    zhCN() {
      return zhCN;
    }
  }
};
</script>

<style lang="less" scoped>
@import './ZgyxLayout.less';
</style>
