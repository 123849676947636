import qs from 'qs';
import axios from 'axios';
import { message } from 'ant-design-vue';
// apis
// utils
// stores
import store from '@src/store';
import router from '@src/router';
// configs
// components
const otherRequests = new Map(); // 其它所有请求只能一次
let timeoutAxiosRef; // 普通请求 禁止多次请求 ref

const request = axios.create({
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  transformRequest: [(data) => qs.stringify(data)]
});
// 请求拦截器
request.interceptors.request.use(
  (config) => {
    const { method = '', baseURL, url = '', params = {}, data = {}, headers = {} } = config;
    const requestParams = qs.stringify({ method, baseURL, url, params, data });

    if (!['get', 'GET'].includes(method)) {
      // if (otherRequests.has(requestParams)) {
      //   process.env.NODE_ENV === 'development' && message.error(`请求已经存在${requestParams}`);
      //   return Promise.reject();
      // }
      otherRequests.set(requestParams, true);
      if (timeoutAxiosRef) {
        clearTimeout(timeoutAxiosRef);
        timeoutAxiosRef = null;
      }
      // timeoutAxiosRef = setTimeout(() => {
      //   otherRequests.clear();
      // }, 1 * 1000);
    }

    const token = store.getters.token ?? '';
    const _headers = token ? Object.assign({}, headers, { Authorization: `Bearer ${token}` }) : headers;
    return Promise.resolve(Object.assign({}, config, { headers: _headers, data, withCredentials: true }));
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 请求完成后的拦截器
request.interceptors.response.use(
  (response) => {
    const { config } = response;
    const { method = '', baseURL, url, params = {}, data = {} } = config;
    const requestParams = qs.stringify({ method, baseURL, url, params, data });
    if (otherRequests.has(requestParams)) {
      otherRequests.delete(requestParams);
    }
    if (response.data.code === 998 || response.data.code === 997) {
      message.error(response.data.message || `认证失效，请重新登陆!`);
      return false;
    }

    if (response.data.code && response.data.code !== '00000') {
      message.error(response.data.message || `接口异常！`);
      // return false;
    }
    return response;
  },
  (error) => {
    // 所有的计数器
    otherRequests.clear();
    return Promise.resolve(error);
  }
);
/**
 * 请求主体函数
 * @param {*} opts
 * @returns
 */
const zgyxRequest = (opts) => {
  const { headers = {}, ...otherOpts } = opts;
  const httpOpts = Object.assign({}, { headers: headers }, otherOpts);
  return new Promise((resolve, reject) => {
    request(httpOpts)
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};
export default zgyxRequest;
export { request };
