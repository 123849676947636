<template>
  <template v-for="subMenu in menuConfigs.filter((subMenu) => !subMenu.meta.hideInMenu)">
    <template v-if="subMenu?.children?.length > 0">
      <a-sub-menu :key="`/${subMenu.path}`" :title="subMenu.meta.name">
        <template #icon>
          <font-awesome-icon :icon="subMenu.meta.icon ?? 'folder'" />
        </template>
        <Menus :menuConfigs="subMenu.children" :parentPath="subMenu.path" />
      </a-sub-menu>
    </template>
    <template v-else>
      <a-menu-item :key="parentPath ? `${parentPath}/${subMenu.path}` : `/${subMenu.path}`" :title="subMenu.meta.name">
        <template #icon>
          <font-awesome-icon :icon="subMenu.meta.icon ?? 'file'" />
        </template>
        {{ subMenu.meta.name }}
      </a-menu-item>
    </template>
  </template>
</template>
<script>
import { FolderOutlined, FileOutlined } from '@ant-design/icons-vue';
export default {
  name: 'Menus',
  components: { FolderOutlined, FileOutlined },
  props: {
    // 父级路径
    parentPath: {
      type: String,
      default: ''
    },
    // 菜单配置信息
    menuConfigs: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="less"></style>
