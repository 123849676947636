import NProgress from 'nprogress';
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@src/store';
import routes from './routers';

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
});

let timeout = null;

router.beforeEach((to, from, next) => {
  NProgress.configure({ showSpinner: false });
  NProgress.start();

  const { token, activeMenu, userinfo } = store.getters;

  const toPath = to.fullPath ? to.fullPath.split('?')[0] : '';
  const title = to.meta.name || '';
  if (activeMenu.path !== toPath) {
    store.dispatch('setActiveMenuReplace', { path: toPath, name: title, query: to.query || {} });
  }

  const {
    path,
    meta: { auth, scopeAuth }
  } = to;

  const { roles, regionCode } = userinfo;

  let scopeType = '';
  if (regionCode) {
    const len = regionCode.length;
    switch (len) {
      case 1:
        scopeType = 'zg';
        break;
      case 2:
        scopeType = 'province';
        break;
      case 4:
        scopeType = 'city';
        break;
      case 6:
        scopeType = 'county';
        break;
      case 9:
        scopeType = 'town';
        break;
      case 12:
        scopeType = 'village';
        break;
      default:
        break;
    }
  }

  if (!token && path !== '/login') {
    next({ path: '/login' });
  } else if (auth) {
    // 如果当前菜单需要权限
    let a = new Set(auth);
    let b = new Set(roles);
    let intersection = new Set([...a].filter((x) => b.has(x))); //2个数组的交集
    if (intersection.size) {
      //存在交集（用户有当前菜单权限）
      if (scopeAuth) {
        if (scopeAuth.includes(scopeType)) {
          //有省市权限（用户有当前菜单权限）
          next();
        } else {
          next({ path: '/login' });
        }
      } else {
        //无省市权限，则不校验权限直接输出
        next();
      }
    } else {
      next({ path: '/login' });
    }
  } else {
    next();
  }
  timeout = setTimeout(() => {
    NProgress.done();
  }, 300);
});
export default router;
