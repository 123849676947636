/*
 * @Author: zzc
 * @Date: 2022-04-21 14:44:14
 * @Last Modified by: lf
 * @Last Modified time: 2023-02-27 18:25:09
 */
import { get, isArray, isNumber, set } from 'lodash';
/**
 * 生成key
 * @param {*} keyArr
 * @returns
 */
const computedKey = (keyArr, joinString = '$') => {
  try {
    return isArray(keyArr) ? keyArr.join(joinString) : keyArr;
  } catch (error) {
    console.error(error);
  }
};
/**
 * 生成range-picker的key
 * @param {*} param0
 * @returns
 */
const computedRangePickerKey = ([keys1, keys2]) => {
  try {
    const _keys1 = computedKey(keys1);
    const _keys2 = computedKey(keys2);
    return computedKey([_keys1, _keys2], '@');
  } catch (error) {
    console.error(error);
  }
};

const computedCascaderKey = (key) => {
  try {
    let cascaderList = [];
    key.map((item, index) => {
      cascaderList.push(computedKey(item));
    });
    return computedKey(cascaderList, '@');
  } catch (error) {
    console.error(error);
  }
};

/**
 * 生成 rules
 * @param {*} rules
 */
const computedRules = ({ rules, label }) => {
  try {
    return rules
      ? rules.map((rule) => {
          if (rule.required === true && label && rule.validator == undefined) {
            return { ...rule, message: `${label}是必填项！` };
          } else {
            return rule;
          }
        })
      : null;
  } catch (error) {
    console.error(error);
  }
};
/**
 * 生成合适的select options的数组
 * @param {*} options
 * @param {*} config
 */
const computedSelectOptions = (options, selectConfig = { labelKey: 'name', valueKey: 'value' }) => {
  try {
    const { labelKey, valueKey } = selectConfig;
    return options.map((el) => ({ ...el, label: el[labelKey], value: el[valueKey] }));
  } catch (error) {
    console.error(error);
  }
};

const createTree = (options) => {
  return options.map((option) => {
    const { name, value, children } = option;

    return {
      ...option,
      label: name,
      value: value,
      children: children?.length ? createTree(children) : null
    };
  });
};

/**
 * 生产远程文件
 * @param {*} fileStrings
 */
const computedRemoteFiles = (fileStrings) => {
  try {
    if (isNumber(fileStrings)) {
      return [`${fileStrings}`];
    }
    return fileStrings ? fileStrings.split(',') : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 生成内部用的formState的数据
 * @param {*} configs
 * @param {*} formState
 * @returns
 */
const createInnerFormState = (configs, formData) => {
  try {
    let innerFormState = {};
    configs.forEach((config) => {
      const { key, type } = config;
      if (type === 'rangePicker') {
        // 注意此处为数组
        const [key1, key2] = key;
        const newKey = computedRangePickerKey(key);
        const value1 = get(formData, key1);
        const value2 = get(formData, key2);
        innerFormState[newKey] = value1 && value2 ? [value1, value2] : null;
      } else if (type === 'cascader') {
        let cascaderList = [];
        const newKey = computedCascaderKey(key);
        key.map((item, index) => {
          cascaderList.push(get(formData, item));
        });
        innerFormState[newKey] = cascaderList ?? null;
      } else {
        const newKey = computedKey(key);
        const value = get(formData, key);
        innerFormState[newKey] = value ?? null;
      }
    });
    return innerFormState;
  } catch (error) {
    console.error(error);
  }
};

const createOutFormData = (configs, formState) => {
  try {
    let outFormData = {};
    configs.forEach((config) => {
      const { key, type } = config;
      if (type === 'rangePicker') {
        const [key1, key2] = key;
        const newKey = computedRangePickerKey(key);
        const value = get(formState, newKey) || [];
        const [value1, value2] = value;
        set(outFormData, key1, value1 || null);
        set(outFormData, key2, value2 || null);
      } else if (type === 'cascader') {
        const newKey = computedCascaderKey(key);
        const value = get(formState, newKey) || [];
        key.map((item, index) => {
          set(outFormData, key[index], value[index] || null);
        });
      } else {
        const newKey = computedKey(key);

        const value = get(formState, newKey);
        set(outFormData, key, value);
      }
    });
    return outFormData;
  } catch (error) {
    console.error(error);
  }
};

/**
 * 获取嵌套对象的值
 * @param {*} obj
 * @param {*} keyArr
 */
const getObjectValue = (obj, keyArr) => {
  try {
    return get(obj, keyArr) ?? null;
  } catch (error) {
    console.error(error);
  }
};

export {
  computedKey,
  computedRangePickerKey,
  computedCascaderKey,
  computedRules,
  createTree,
  computedSelectOptions,
  computedRemoteFiles,
  createInnerFormState,
  createOutFormData,
  getObjectValue
};
