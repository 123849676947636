<template>
  <section class="layout">
    <div class="layout-title">
      <div class="title">浙江省老年友好型社区创建</div>
      <div class="sub">信息上报系统</div>
    </div>
    <div class="form-wrap" v-if="showLoginFormFlag">
      <div class="form-title">登录</div>
      <a-form ref="formRef" class="form-content" :model="form" @finish="login" autocomplete="off">
        <a-form-item label="" name="username" :rules="[{ required: true, message: '请输入用户账号' }]">
          <section class="form-item-content">
            <a-input v-model:value="form.username" size="large" placeholder="请输入用户账号">
              <template #prefix>
                <UserOutlined class="icon" />
              </template>
            </a-input>
          </section>
        </a-form-item>
        <a-form-item label="" name="password" :rules="[{ required: true, message: '请输入登录密码' }]">
          <section class="form-item-content">
            <a-input-password v-model:value="form.password" size="large" placeholder="请输入登录密码">
              <template #prefix>
                <LockOutlined class="icon" />
              </template>
            </a-input-password>
          </section>
        </a-form-item>

        <a-form-item label="" name="verifyCode" :rules="[{ required: true, message: '请输入验证码' }]">
          <section class="form-item-content">
            <a-row type="flex" :wrap="false">
              <a-col flex="auto">
                <a-input v-model:value="form.verifyCode" size="large" placeholder="请输入验证码"> </a-input>
              </a-col>
              <a-col flex="12px"></a-col>
              <a-col flex="160px">
                <img class="captcha" :src="captcha" alt="验证码" @click="getSysCaptchaRefresh" />
              </a-col>
            </a-row>
          </section>
        </a-form-item>

        <a-form-item>
          <section class="form-item-content">
            <a-button type="primary" html-type="submit" block size="large">登录</a-button>
          </section>
        </a-form-item>
      </a-form>
      <!-- <div class="forgot-password" @click="showLoginFormFlag = false">忘记密码</div> -->
    </div>
    <div class="form-wrap2" v-else>
      <div class="form-title">设置新密码</div>
      <a-form
        ref="formRef2"
        class="form-content2"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :model="formForget"
        autocomplete="off"
        :rules="rules"
      >
        <a-form-item label="登录账号" name="username">
          <section class="form-item-content">
            <a-input v-model:value="formForget.username" size="large" placeholder="请输入登录账号" allowClear>
            </a-input>
          </section>
        </a-form-item>

        <a-form-item label="预留手机号" name="mobile">
          <section class="form-item-content">
            <a-input-number v-model:value="formForget.mobile" size="large" placeholder="请输入预留手机号">
            </a-input-number>
          </section>
        </a-form-item>

        <a-form-item label="设置密码" name="password">
          <section class="form-item-content">
            <a-input v-model:value="formForget.password" size="large" placeholder="建议至少8位,三种字符组合" allowClear>
            </a-input>
          </section>
        </a-form-item>
        <a-form-item label="确认密码" name="password">
          <section class="form-item-content">
            <a-input v-model:value="formForget.passwordConfirm" size="large" placeholder="请再次输入密码" allowClear>
            </a-input>
          </section>
        </a-form-item>
        <a-form-item label="手机验证码" name="verifyCode">
          <section class="form-item-content">
            <a-row type="flex">
              <a-col flex="auto">
                <a-input-number v-model:value="formForget.verifyCode" size="large" placeholder="请输入手机验证码">
                </a-input-number>
              </a-col>
              <a-col flex="12px"></a-col>
              <a-col flex="100px">
                <a-button type="primary" size="large" block @click="send" :disabled="bVerification">{{
                  countDown
                }}</a-button>
              </a-col>
            </a-row>
          </section>
        </a-form-item>
        <section class="form-item-content2">
          <a-button type="primary" block size="large" @click="handlePostResetPasswordVerifyCode">提交</a-button>
        </section>
      </a-form>
      <div class="forgot-password" @click="showLoginFormFlag = true">返回登录</div>
    </div>
  </section>
</template>

<script>
import { message } from 'ant-design-vue';
import { mapActions, mapGetters } from 'vuex';
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue';
import {
  postLogin,
  getDictionary,
  getSmsRestPassword,
  getVrifyCodeImage,
  postResetPasswordVerifyCode
} from '@src/apis/index';
import { createJSEncryptPassword } from './utils';
const checkPassword = (rule, value, callback) => {
  const reg =
    /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,}$/;
  if (!value) {
    return Promise.reject('请输入密码');
  } else if (!reg.test(value)) {
    return Promise.reject('请输入至少8位并同时包含大写字母、小写字母、数字、符号中的其中三种的密码');
  } else {
    return Promise.resolve();
  }
};

const checkMobile = (rule, value, callback) => {
  const reg = /^1[3-8]\d{9}$/;
  if (!value) {
    return Promise.reject('请输入手机号');
  } else if (!reg.test(value)) {
    return Promise.reject('请输入正确格式的手机号');
  } else {
    return Promise.resolve();
  }
};

export default {
  name: 'Login',
  components: {
    LockOutlined,
    UserOutlined
  },
  data() {
    const form = { username: '', password: '', verifyCode: '' };
    const formForget = { username: '', mobile: '', password: '', passwordConfirm: '', verifyCode: '' };
    return {
      form,
      formForget,
      showLoginFormFlag: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      countDown: '发送验证码', // 倒计时
      bVerification: false, // 节流
      verifyCodeKey: '', //登录的key
      captcha: '',
      rules: {
        username: [{ required: true, message: '请输入登录账号' }],
        mobile: [{ required: true, message: '请输入登录账号', validator: checkMobile }],
        password: [{ required: true, validator: checkPassword, message: '请输入密码' }],
        passwordConfirm: [{ required: true, validator: checkPassword, message: '请再次输入密码' }],
        verifyCode: [{ required: true, message: '请输入验证码' }]
      }
    };
  },
  computed: {
    ...mapGetters({})
  },
  created() {
    const _this = this;
    _this.setDictionaries({});
    _this.setUserinfo({});
    _this.setToken('');

    _this.getSysCaptcha();
  },
  mounted() {
    const _this = this;
  },
  methods: {
    ...mapActions(['setDictionaries', 'setUserinfo', 'setToken']),
    /**
     * 重复刷新验证码
     */
    async getSysCaptchaRefresh() {
      try {
        const _this = this;
        const { code, data } = await getVrifyCodeImage({ key: _this.verifyCodeKey });
        if (code === '00000') {
          _this.captcha = `data:image/png;base64,${data}`;
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 获取验证码
     */
    async getSysCaptcha() {
      try {
        const _this = this;
        const uuid = _this.getUuid();
        const { code, data } = await getVrifyCodeImage({ key: uuid });
        if (code === '00000') {
          _this.verifyCodeKey = uuid;
          _this.captcha = `data:image/png;base64,${data}`;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getUuid() {
      var s = [];
      var hexDigits = '0123456789abcdef';
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '-';

      var uuid = s.join('');
      return uuid;
    },
    async send() {
      try {
        const _this = this;
        if (!this.formForget.username) return message.error('请输入登录账号');
        if (!/^1[3-8]\d{9}$/.test(this.formForget.mobile)) return message.error('请输入正确的手机号');
        if (this.bVerification) return;
        this.bVerification = true;
        const { code } = await getSmsRestPassword({
          mobile: this.formForget.mobile,
          username: this.formForget.username
        });

        if (code === 200) {
          message.success('发送成功');
          let countDown = 59;
          const auth_time = setInterval(() => {
            this.countDown = countDown--;
            if (this.countDown <= 0) {
              this.bVerification = false;
              this.countDown = '发送验证码';
              clearInterval(auth_time);
            }
          }, 1000);
        } else {
          this.bVerification = false;
        }
      } catch (error) {
        this.bVerification = false;
      }
    },
    /**
     * 获取字典
     */
    async getDictionary() {
      try {
        const _this = this;
        const { code, data } = await getDictionary();
        if (code === '00000') {
          _this.setDictionaries(data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 登录接口
     */
    async login(values) {
      try {
        const _this = this;
        await _this.$refs.formRef
          .validate()
          .then(async () => {
            const _password = createJSEncryptPassword(values.password);
            const {
              code,
              data = {},
              message: message
            } = await postLogin(Object.assign({ verifyCodeKey: _this.verifyCodeKey }, values, { password: _password }));

            if (code === '00000') {
              const { token } = data;
              _this.setToken(token);
              _this.setUserinfo(data);
              await _this.getDictionary();
              _this.$router.push({ path: '/' });
            } else {
              _this.getSysCaptcha();
              // message.error(message ?? '失败');
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      } catch (error) {
        console.error(error);
      }
    },
    async handlePostResetPasswordVerifyCode() {
      try {
        const _this = this;
        await _this.$refs.formRef2
          .validate()
          .then(async () => {
            const _password = createJSEncryptPassword(_this.formForget.password);
            const _passwordConfirm = createJSEncryptPassword(_this.formForget.passwordConfirm);
            const {
              code,
              data = {},
              message: message
            } = await postResetPasswordVerifyCode(
              Object.assign({}, _this.formForget, { password: _password, passwordConfirm: _passwordConfirm })
            );
            if (code === '00000') {
              message.success('重置成功');
              _this.formForget = {
                username: '',
                mobile: '',
                password: '',
                passwordConfirm: '',
                verifyCode: ''
              };
              _this.showLoginFormFlag = true;
            } else {
              // message.error(message ?? '失败');
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import './Login.less';
</style>
<style lang="less">
.form-wrap2 {
  .ant-input-number {
    width: 100% !important;
  }
  .ant-form-item-label > label {
    height: 40px;
    line-height: 40px;
  }
}
.form-wrap {
  .ant-input-number {
    width: 100% !important;
  }
}

.captcha {
  display: inline-block;
  vertical-align: top;
  width: 160px;
  height: 40px;
  object-fit: contain;
}
</style>
