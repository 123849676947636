<template>
  <a-cascader
    :value="areaValues"
    :options="areaTreeDatas"
    :load-data="loadData"
    @change="onChangeAreaValues"
    placeholder="请选择"
    change-on-select
  />
</template>

<script>
import { mapGetters } from 'vuex';
// apis
import { getRegion } from '@src/apis';
// utils
// import { getTreeDataTitleByKey } from '@src/utils';
import { createAreaTree } from './utils';
// mixins
// configs
// components
export default {
  name: 'BusnessAreaCascader',
  components: {},
  emits: ['update:areaValues', 'change', 'update:areaNames'],
  props: {
    areaValues: {
      type: Array,
      default() {
        return [];
      }
    },
    areakey: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      areaTreeDatas: []
    };
  },
  computed: {
    ...mapGetters(['userinfo'])
  },
  methods: {
    /**
     * 获取省市区
     */
    async getRegion() {
      try {
        const _this = this;
        const { userinfo } = _this;
        const { code, data } = await getRegion({ regionCode: userinfo.regionCode });
        if (code === '00000' && data?.length) {
          _this.areaTreeDatas = createAreaTree(data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      const { code, data } = await getRegion({ regionCode: targetOption.value });
      targetOption.loading = false;
      if (code === '00000' && data.length) {
        targetOption.children = createAreaTree(data);
      } else {
        targetOption.isLeaf = true;
      }
    },
    /**
     * 修改回调
     */
    onChangeAreaValues(values) {
      try {
        const _this = this;
        _this.$emit('update:areaValues', values, _this.areakey);
        _this.$emit('change', values);
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    const _this = this;
    _this.$nextTick(() => {
      _this.getRegion();
    });
  }
};
</script>

<style lang="less" scoped></style>
