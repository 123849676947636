<template>
  <section class="layout">
    <a-result status="404" title="404"> </a-result>
  </section>
</template>

<script>
export default {
  name: 'page404'
};
</script>

<style lang="less" scoped>
@import url('~@src/styles/configs/index.less');
@import url('~@src/styles/mixins/index.less');

.layout {
  padding: 24px;
  background-color: @zgyx-bg-color;
}
</style>
