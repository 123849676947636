import { RouterView } from 'vue-router';
import Login from '@src/layouts/login/Login';
import Root from '@src/layouts/root/Root';
import Page404 from '@src/layouts/404/Page404';
/**
 * 备注
 * 菜单分成三部分,社区，省级
 * meta参数描述
 * name：标题
 * onwer：所有者（无必要参数）
 * hideInMenu：是否在左侧菜单隐藏
 */
/**
 * 社区
 */
const menuConfigs = [
  {
    path: 'index',
    meta: { name: '首页', icon: 'home' },
    component: () => import('@src/pages/index')
  },
  {
    path: 'quota',
    meta: { name: '社区填报', auth: ['tenant'], scopeAuth: ['village'], icon: 'clipboard' },
    component: () => import('@src/pages/community/quota')
  },
  {
    path: 'subAccount',
    meta: { name: '子账户', auth: ['tenant'], scopeAuth: ['village'], icon: 'user-friends' },
    component: () => import('@src/pages/community/subAccount')
  },
  {
    path: 'copy',
    meta: { name: '自评详情', auth: ['tenant'], hideInMenu: true },
    component: () => import('@src/pages/community/copy')
  },
  {
    path: 'assessment',
    meta: { name: '自评详情', auth: ['tenant'], hideInMenu: true },
    component: () => import('@src/pages/community/assessment')
  },
  {
    path: 'task',
    meta: { name: '台账列表', auth: ['tenant'], scopeAuth: ['village'], icon: 'fa-folder' },
    component: () => import('@src/pages/community/task')
  },

  {
    path: 'countyExamine',
    meta: { name: '县（市、区）核准', auth: ['expert', 'tenant'], scopeAuth: ['county'], icon: 'balance-scale' },
    component: () => import('@src/pages/county/examineSearch')
  },
  {
    path: 'countyExamineDetail',
    meta: { name: '县（市、区）核准详情', auth: ['expert', 'tenant'], scopeAuth: ['county'], hideInMenu: true },
    component: () => import('@src/pages/county/audit')
  },
  {
    path: 'cityExamine',
    meta: { name: '市级评分', auth: ['expert', 'tenant'], scopeAuth: ['city'], icon: 'balance-scale' },
    component: () => import('@src/pages/city/examineSearch')
  },
  {
    path: 'cityExamineDetail',
    meta: { name: '市级评分详情', auth: ['expert', 'tenant'], scopeAuth: ['city'], hideInMenu: true },
    component: () => import('@src/pages/city/audit')
  },
  {
    path: 'provinceExamine',
    meta: { name: '省级评审', auth: ['expert', 'tenant'], scopeAuth: ['province'], icon: 'balance-scale' },
    component: () => import('@src/pages/province/examineSearch')
  },
  {
    path: 'provinceExamineDetail',
    meta: { name: '省级评审详情', auth: ['expert', 'tenant'], scopeAuth: ['province'], hideInMenu: true },
    component: () => import('@src/pages/province/audit')
  },
  {
    path: 'userManagements',
    meta: { name: '用户管理', auth: ['admin'], icon: 'user-friends' },
    component: () => import('@src/pages/userManagements')
  },
  { path: '/:pathMatch(.*)', meta: { name: '通配', hideInMenu: true }, component: Page404 }
];

export { menuConfigs };

export default [
  { path: '/login', component: Login },
  {
    path: '/',
    redirect: '/index',
    component: Root,
    children: menuConfigs
  },
  { path: '/*', component: Root }
];
