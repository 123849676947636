/*
 * @Author: zzc
 * @Date: 2022-04-21 14:44:14
 * @Last Modified by: lf
 * @Last Modified time: 2022-10-04 12:25:01
 */
import { set, get, isArray } from 'lodash';
/**
 * 生成query
 * @param {*} formConfigs
 * @returns
 */
const createQuery = (formConfigs) => {
  let query = {}; // 请求参数
  formConfigs.forEach((elem) => {
    const { key } = elem;
    if (elem.type === 'rangePicker') {
      const [key1, key2] = key;
      set(query, key1, '');
      set(query, key2, '');
    } else if (elem.type === 'rangeNumber') {
      const [key1, key2] = key;
      set(query, key1, null);
      set(query, key2, null);
    } else if (elem.type === 'cascader') {
      key.map((item) => {
        set(query, item, null);
      });
    } else if (elem.type === 'region') {
      const [key1, key2] = key;
      set(query, key1, null);
      set(query, key2, null);
    } else {
      set(query, key, null);
    }
  });
  return query;
};
/**
 * 生成合适的key
 * @param {*} keyArr
 */
const createKey = (keyArr) => {
  return isArray(keyArr) ? keyArr.join('.') : keyArr;
};
/**
 * 生成合适的select options的数组
 * @param {*} options
 * @param {*} config
 */
const createSelectOptions = (options, selectConfig = { labelKey: 'name', valueKey: 'value' }) => {
  const { labelKey, valueKey } = selectConfig;
  return options.map((el) => ({ ...el, label: el[labelKey], value: el[valueKey] }));
};

const createTree = (options) => {
  return options.map((option) => {
    const { name, value, children } = option;

    return {
      ...option,
      label: name,
      value: value,
      children: children?.length ? createTree(children) : null
    };
  });
};

/**
 * 生成配置文件
 * @param {*} configs
 */
const createRequestParams = (configs, query) => {
  try {
    let params = {};
    configs.forEach((config) => {
      const { key, type } = config;
      if (type === 'rangePicker' && config.format === 'YYYY-MM-DD') {
        const [key1, key2] = key;
        const value1 = get(query, key1);
        const value2 = get(query, key2);
        set(params, key1, value1 ? `${value1} 00:00:00` : '');
        set(params, key2, value2 ? `${value2} 23:59:59` : '');
      } else if (type === 'rangePicker' && config.format === 'YYYY-MM-DD HH:mm:ss') {
        const [key1, key2] = key;
        const value1 = get(query, key1);
        const value2 = get(query, key2);
        if (value1 && value2) {
          set(params, key1, value1 ? `${value1}` : '');
          set(params, key2, value2 ? `${value2}` : '');
        }
      } else if (type === 'rangeNumber') {
        const [key1, key2] = key;
        set(params, key1, get(query, key1));
        set(params, key2, get(query, key2));
      } else if (type === 'cascader') {
        key.map((item) => {
          set(params, item, get(query, item));
        });
      } else if (type === 'region') {
        const [key1, key2] = key;
        // set(params, key1, get(query, key1));
        set(params, key2, get(query, key2));
      } else {
        set(params, key, get(query, key));
      }
    });
    return params;
  } catch (error) {}
};
/**
 * 获取嵌套对象的值
 * @param {*} obj
 * @param {*} keyArr
 */
const getObjectValue = (formData, keyArr) => {
  return get(formData, keyArr);
};
/**
 * 获取rangerpicker
 * @param {*} param0
 * @param {*} formData
 */
const getRangePickerMomentTime = (formData, [key1, key2]) => {
  const time1 = getObjectValue(formData, key1);
  const time2 = getObjectValue(formData, key2);
  return time1 && time2 ? [time1, time2] : null;
};

const getRangeNumberStartValue = (formData, [key1, key2]) => {
  return getObjectValue(formData, key1);
};
const getRangeNumberEndValue = (formData, [key1, key2]) => {
  return getObjectValue(formData, key2);
};

const getCascader = (formData, key) => {
  let cascaderList = [];
  key.map((item) => {
    cascaderList.push(getObjectValue(formData, item));
  });
  return cascaderList;
};

export {
  createQuery,
  createKey,
  createSelectOptions,
  createTree,
  createRequestParams,
  getObjectValue,
  getRangePickerMomentTime,
  getRangeNumberStartValue,
  getRangeNumberEndValue,
  getCascader
};
