<template>
  <div ref="newModal">
    <a-modal
      :getContainer="() => $refs.newModal"
      title="个人信息"
      visible
      :width="800"
      @cancel="onCancel"
      @ok="onOk"
      :maskClosable="false"
      :keyboard="false"
    >
      <a-spin :spinning="spinning" tip="加载中" size="large">
        <section class="layout">
          <ZgyxFormGirdEdit
            ref="girdFormEdit"
            :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18 }"
            :formData="formState"
            :configs="[
              { span: 24, type: 'input', label: '姓名', key: 'nickName', rules: [{ required: true }] },
              {
                span: 24,
                type: 'input',
                label: '手机号码',
                key: 'mobile',
                rules: [
                  { required: true, message: '请输入手机号码' },
                  { pattern: regExpCheckPhone, message: '手机号格式不正确' }
                ]
              }
            ]"
            @update:formData="(formData) => (formState = formData)"
          >
          </ZgyxFormGirdEdit>
        </section>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { ZgyxFormGirdEdit } from '@src/components';
import { apiGetUserInfoOther, apiPostSaveUserInfoByOther } from '@src/apis';
import { regExpCheckPhone, regExpCheckEmail } from '@src/configs';
export default {
  name: '',
  components: { ZgyxFormGirdEdit },
  props: {},
  data() {
    const formState = {
      nickName: '',
      mobile: ''
    };
    return {
      formState,
      spinning: false
    };
  },
  computed: {
    ...mapGetters(['dictionaries', 'userinfo']),
    // 手机号格式
    regExpCheckPhone() {
      return regExpCheckPhone;
    },
    regExpCheckEmail() {
      return regExpCheckEmail;
    }
  },
  created() {},
  mounted() {
    const _this = this;
    _this.$nextTick(async () => {
      _this.handleGetInfo();
    });
  },
  methods: {
    /*
     *获取详情
     */
    async handleGetInfo() {
      try {
        const _this = this;
        const { code, data } = await apiGetUserInfoOther(_this.userinfo.id);

        if (code === '00000' && data) {
          _this.formState = Object.assign({}, _this.formState, data ?? {});
        }
      } catch (error) {
        console.error(error);
      }
    },
    /*
     * 更新
     */
    async handleUpdate() {
      try {
        const _this = this;
        const { formState } = this;
        let sendData = Object.assign({ accountId: _this.userinfo.id }, formState);
        const { code, msg } = await apiPostSaveUserInfoByOther(sendData);
        if (code === '00000') {
          message.success(msg ?? '成功');
          _this.$emit('onOk');
        } else {
          // message.error(msg ?? '失败');
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击确定
     */
    async onOk() {
      try {
        const _this = this;
        const { isOk } = await _this.$refs.girdFormEdit.validate();
        if (isOk) {
          _this.handleUpdate();
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击取消
     */
    onCancel() {
      try {
        const _this = this;
        _this.$emit('onCancel');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  height: 40vh;
  padding: 20px;
  overflow-y: auto;
}
</style>
