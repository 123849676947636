import { cloneDeep } from 'lodash';
import { createStore } from 'vuex';
import persistedState from 'vuex-persistedstate';
// apis
// utils
// stores
// configs
import router from '@src/router';
import * as types from './mutation-types';

export default createStore({
  state: {
    userinfo: {},
    token: '',
    dictionaries: {},
    activeMenu: { path: '/index', name: '首页' }
  },
  getters: {
    userinfo: (state) => state.userinfo,
    token: (state) => state.token,
    dictionaries: (state) => state.dictionaries,
    activeMenu: (state) => state.activeMenu
  },
  mutations: {
    [types.SET_USER_INFO](state, userinfo) {
      state.userinfo = userinfo;
    },
    [types.SET_TOKEN](state, token) {
      state.token = token;
    },
    [types.SET_DICTIONARIES](state, dictionaries) {
      state.dictionaries = dictionaries;
    },
    [types.SET_ACTIVEMENU](state, activeMenu) {
      state.activeMenu = activeMenu;
      router.push({ path: `${activeMenu.path}`, query: activeMenu.query || {} });
    },
    [types.SET_ACTIVEMENUREPLACE](state, activeMenu) {
      state.activeMenu = activeMenu;
      router.replace({ path: `${activeMenu.path}`, query: activeMenu.query || {} });
    }
  },
  actions: {
    setUserinfo({ commit }, userinfo) {
      commit(types.SET_USER_INFO, userinfo);
    },
    setToken({ commit }, token) {
      commit(types.SET_TOKEN, token);
    },
    setDictionaries({ commit }, dictionaries) {
      commit(types.SET_DICTIONARIES, dictionaries);
    },
    setActiveMenu({ commit }, activeMenu) {
      commit(types.SET_ACTIVEMENU, activeMenu);
    },
    setActiveMenuReplace({ commit }, activeMenu) {
      commit(types.SET_ACTIVEMENUREPLACE, activeMenu);
    }
  },
  plugins: [persistedState({ storage: window.sessionStorage })]
});
