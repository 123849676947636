<template>
  <a-upload
    action="/upload/picture/upload"
    :headers="{ Authorization: `Bearer ${token}` }"
    :show-upload-list="false"
    :with-credentials="true"
    :before-upload="onBeforeUpload"
    :disabled="disabled"
    @change="onUploadChange"
  >
    <div class="img-content" v-if="visibleDefaultUpload">
      <CloseCircleOutlined class="icon" @click.stop="onClickDelete" v-if="!disabled" />
      <slot />
    </div>
    <template v-if="visibleUpload">
      <slot name="empty" v-if="visibelEmptySlot" />
      <div class="content" v-else>
        <LoadingOutlined v-if="loading"></LoadingOutlined>
        <PlusOutlined v-else class="icon" />
        <p class="text">{{ text ?? '点击上传' }}</p>
      </div>
    </template>
  </a-upload>
</template>

<script>
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { isArray, isNumber } from 'lodash';
import { PlusOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons-vue';
// apis
// utils
// mixins
// configs
// components
export default {
  name: 'ZgyxUploadImgs',
  components: { PlusOutlined, CloseCircleOutlined, LoadingOutlined },
  props: {
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 最大数组
    maxCount: {
      type: Number,
      default: 1
    },
    // 远程文件地址
    remoteFileIds: {
      type: [String, Number],
      default: ''
    },
    text: String // 描述
  },
  computed: {
    ...mapGetters(['token']),
    /**
     * 本地的ids
     */
    locationFileIds() {
      const { remoteFileIds } = this;
      if (isNumber(remoteFileIds)) {
        return [`${remoteFileIds}`];
      }
      return remoteFileIds ? remoteFileIds.split(',') : [];
    },
    /**
     * 是否显示上传组件
     */
    visibleUpload() {
      const _this = this;
      const { locationFileIds, maxCount } = _this;
      return locationFileIds.length < maxCount;
    },
    /**
     * 是否存在图片
     */
    visibleDefaultUpload() {
      const _this = this;
      return Boolean(_this.$slots.default && _this.remoteFileIds);
    },
    /**
     * 是否使用外部传入的empty组件
     */
    visibelEmptySlot() {
      const _this = this;
      return Boolean(_this.$slots.empty);
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const onBeforeUpload = (file) => {
      try {
        const isOk = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
        if (!isOk) {
          message.error('文件类型不符合要求！');
        }

        const isLtSize = file.size / 1024 / 1024 < 10;
        if (!isLtSize) {
          message.error('图片必须小于10MB!');
        }
        return isOk && isLtSize;
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 修改回调
     */
    const onUploadChange = ({ file }) => {
      try {
        const { status, response } = file;
        if (status === 'uploading') {
          loading.value = true;
          return;
        }

        if (status === 'done') {
          loading.value = false;
          const {
            code,
            data: { fileId }
          } = response;
          if (code === '00000') {
            emit('update:remoteFileIds', fileId);
          }
        }

        if (status === 'error') {
          loading.value = false;
          message.error('上传错误');
        }
      } catch (error) {
        console.error(error);
      }
    };
    const onClickDelete = () => {
      try {
        emit('update:remoteFileIds', '');
      } catch (error) {
        console.error(error);
      }
    };
    return {
      loading,
      onBeforeUpload,
      onUploadChange,
      onClickDelete
    };
  },
  methods: {}
};
</script>

<style lang="less" scoped>
@import url('./ZgyxUploadImgs.less');
</style>
