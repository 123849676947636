function pagePc() {
  let rootValue = 100;

  let pc = rootValue / 1920; // 表示1920的设计图,使用100px的默认值

  let width = window.innerWidth; // 当前窗口的宽度

  let height = window.innerHeight; // 当前窗口的高度

  let rem = '';

  let currentHeight = (width * 930) / 1920;

  if (height < currentHeight) {
    // 当前屏幕高度小于应有的屏幕高度，就需要根据当前屏幕高度重新计算屏幕宽度

    width = (height * 1920) / 930;
  }

  rem = width * pc; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值

  if (rem < 54) {
    rem = 54;
  } else if (rem > 100) {
    rem = 100;
  }

  document.documentElement.style.fontSize = parseInt(rem) + 'px';
  console.log(rem);
}

pagePc();

window.onresize = function () {
  pagePc();
};
