<template>
  <div ref="newModal">
    <a-modal
      :getContainer="() => $refs.newModal"
      :title="file.name || '预览文件'"
      visible
      :width="1400"
      @cancel="onCancel"
      @ok="onOk"
      :maskClosable="false"
      :keyboard="false"
      :footer="null"
    >
      <a-spin :spinning="spinning" tip="加载中" size="large">
        <section class="layout">
          <template v-if="isPdf">
            <pdf v-for="item in numPages" :key="item" :src="pdfSrc" :page="item" ref="pdf"></pdf>
          </template>
          <template v-else>
            <div v-html="htmlUrl"></div>
          </template>
        </section>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import pdf from 'vue3-pdf';
export default {
  name: '',
  components: { pdf },
  props: {
    file: { type: Object, default: () => {} }
  },
  data() {
    return {
      isPdf: true,
      spinning: false,
      pdfSrc: '',
      numPages: null, // pdf 总页数
      htmlUrl: ''
    };
  },
  computed: {},
  async created() {
    const _this = this;
    if (_this.file) {
      _this.spinning = true;
      if (!this.file.fileType || this.file.fileType == 'pdf') {
        this.pdfSrc = this.file.pdfSrc;
        this.$nextTick(() => {
          this.getNumPages();
        });
      } else {
        this.isPdf = false;
        _this.getHtml();
        _this.spinning = false;
      }

      setTimeout(() => {
        _this.spinning = false;
      }, 10000);
    }
  },
  mounted() {
    const _this = this;
  },
  methods: {
    //通过Html地址获取html
    getHtml(params) {
      const _this = this;
      const x = new window.XMLHttpRequest();
      x.open('GET', _this.file.pdfSrc, true);
      x.responseType = 'blob';
      x.onload = () => {
        //x.response
        _this.blobToString(x.response, function (str) {
          _this.htmlUrl = str;
        });
      };
      x.send();
    },
    blobToString(blob, callback) {
      var reader = new FileReader();
      reader.onload = function () {
        var dataUrl = reader.result;
        var base64 = dataUrl.split(',')[1]; // 将 dataUrl 转换为 base64 编码的字符串
        var decodedData = window.atob(base64); // 解码 base64
        callback(decodeURIComponent(escape(decodedData)));
      };
      reader.readAsDataURL(blob);
    },
    getNumPages() {
      const _this = this;
      let loadingTask = pdf.createLoadingTask(this.pdfSrc);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
          _this.spinning = false;
        })
        .catch((err) => {
          console.error('pdf 加载失败', err);
        });
    },
    /**
     * 点击确定
     */
    async onOk() {
      try {
        const _this = this;
        _this.$emit('onOk');
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击取消
     */
    onCancel() {
      try {
        const _this = this;
        _this.$emit('onCancel');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  height: 75vh;
  padding: 0;
  overflow-y: auto;
}
.docWrap {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
