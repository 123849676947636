import { zgyxRequest, request } from '@src/utils';
const baseUrlFix = '/cmsapi';
/**
 * 获取字典
 * @returns
 */
const getDictionary = () => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/common/dictionary' });
};
/**
 * 登录
 * @param {*} data
 * @returns
 */
const postLogin = (data = { username: '', password: '' }) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/login', data });
};

/**
 * 忘记密码重置
 * @param {*} data
 * @returns
 */
const postResetPasswordVerifyCode = (data = { username: '', password: '' }) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/reset-password/verify-code', data });
};
/**
 * 获取图形验证码
 * @returns
 */
const getVrifyCodeImage = (params) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/common/verify-code/image/get', params });
};

/**
 * 获取验证码
 * @returns
 */
const getSmsRestPassword = (params) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/sms/rest-password/send', params });
};

/**
 * 获取省市区
 * @returns
 */
const getRegion = (params) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/system/region/listByParentCode', params });
};

/**
 * 获取资源
 * @param {*} data
 * @returns
 */
const getFile = (params = { id: '' }) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/upload/getFile', params });
};

/**
 * 用户列表
 * @param {*} data
 * @returns
 */
const apiGetCmsSysUserSearch = (params = {}) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/system/account/search', params });
};

/**
 * 创建用户
 * @param {*} data
 * @returns
 */
const apiPostCmsSysUserSave = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/create', data });
};

/**
 * 更新用户信息
 * @param {*} data
 * @returns
 */
const apiPostCmsSysUserUpdate = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/update', data });
};

/**
 * 重置密码
 * @param {*} data
 * @returns
 */
const apiPostAccountChangePassword = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/resetPassword', data });
};

/**
 * 启用禁用
 * @param {*} data
 * @returns
 */
const apiPostAccountChangeStatus = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/changeStatus', data });
};

/**
 * 市评分列表
 * @param {*} data
 * @returns
 */
const apiGetCmsSelectionResultCitySearch = (params = {}) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/selection/project-result/city/search', params });
};
/**
 * 市评分列表-下载
 * @param {*} data
 * @returns
 */
const apiGetCmsSelectionResultCityDownLoad = (params = {}) => {
  return request({
    method: 'get',
    url: `${baseUrlFix}/selection/project-result/city/download`,
    params,
    responseType: 'blob'
  });
};

/**
 * 省评分列表
 * @param {*} data
 * @returns
 */
const apiGetCmsSelectionResultProvinceSearch = (params = {}) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/selection/project-result/province/search', params });
};

/**
 * 省评分列表
 * @param {*} data
 * @returns
 */
const apiGetCmsSelectionResultProvinceDownLoad = (params = {}) => {
  return request({
    method: 'get',
    url: `${baseUrlFix}/selection/project-result/province/download`,
    params,
    responseType: 'blob'
  });
};

/**
 * 用户个人信息
 * @param {*} accountId
 * @returns
 */
const apiGetUserInfo = (accountId) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/system/apply-unit/info`
  });
};

/**
 * 用户信息是否完善accountId
 * @param {*}
 * @returns
 */
const apiGetUserExist = (accountId) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/system/apply-unit/exist`
  });
};

/**
 * 保存用户信息
 * @param {*} data
 * @returns
 */
const apiPostSaveUserInfo = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/apply-unit/save-or-update', data });
};

/**
 * 预览文件
 * @param {*} data
 * @returns
 */
const previewPdfApi = (params) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/system/upload/preview/depth', params });
};

/**
 * 审核
 * @param {*} data
 * @returns
 */
const apiPostCountyAuditing = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project-result/county/auditing', data });
};

/**
 * 区评分列表
 * @param {*} data
 * @returns
 */
const apiGetCmsSelectionResultCountySearch = (params = {}) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/selection/project-result/county/search', params });
};

//20230301修改需求
/**
 * 用户信息是否完善区，市，省 Other accountId
 * @param {*}
 * @returns
 */
const apiGetUserExistOther = (params) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/system/account/check-integrity`,
    params
  });
};
/**
 * 更新用户信息区，市，省
 * @param {*} data
 * @returns
 */
const apiPostSaveUserInfoByOther = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/update/my', data });
};

/**
 * 获取用户信息区，市，省
 * @param {*} accountId
 * @returns
 */
const apiGetUserInfoOther = (accountId) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/system/account/info/${accountId}`
  });
};

/**
 * 用户修改密码区，市，省
 * @param {*} data
 * @returns
 */
const apiPostAccountUpdatePassword = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/updatePassword', data });
};

/**
 * 一键重置密码、admin
 * @param {*} data
 * @returns
 */
const apiPostAccountResetDefaultPassword = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/reset-default-password', data });
};

/*
/selection/project-result/county/detail/download
/selection/project-result/city/detail/download
/selection/project-result/province/detail/download
*/
/**
 * 区下载
 * @param {*} data
 * @returns
 */
const countyDetailDownLoad = (params = {}) => {
  return request({
    method: 'get',
    url: `${baseUrlFix}/selection/project-result/county/detail/download`,
    params,
    responseType: 'blob'
  });
};
/**
 * 市下载
 * @param {*} data
 * @returns
 */
const cityDetailDownLoad = (params = {}) => {
  return request({
    method: 'get',
    url: `${baseUrlFix}/selection/project-result/city/detail/download`,
    params,
    responseType: 'blob'
  });
};
/**
 * 省下载
 * @param {*} data
 * @returns
 */
const provinceDetailDownLoad = (params = {}) => {
  return request({
    method: 'get',
    url: `${baseUrlFix}/selection/project-result/province/detail/download`,
    params,
    responseType: 'blob'
  });
};

export {
  getDictionary,
  postLogin,
  getVrifyCodeImage,
  getSmsRestPassword,
  postResetPasswordVerifyCode,
  getRegion,
  getFile,
  apiGetCmsSysUserSearch,
  apiPostCmsSysUserSave,
  apiPostCmsSysUserUpdate,
  apiPostAccountChangePassword,
  apiPostAccountResetDefaultPassword,
  apiPostAccountChangeStatus,
  apiGetCmsSelectionResultCitySearch,
  apiGetCmsSelectionResultCityDownLoad,
  apiGetCmsSelectionResultProvinceSearch,
  apiGetCmsSelectionResultProvinceDownLoad,
  apiGetUserInfo,
  apiGetUserExist,
  apiPostSaveUserInfo,
  previewPdfApi,
  apiPostCountyAuditing,
  apiGetCmsSelectionResultCountySearch,
  apiPostSaveUserInfoByOther,
  apiGetUserExistOther,
  apiGetUserInfoOther,
  apiPostAccountUpdatePassword,
  countyDetailDownLoad,
  cityDetailDownLoad,
  provinceDetailDownLoad
};
