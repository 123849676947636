<template>
  <a-form
    class="form"
    ref="formRef"
    :model="formState"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    autocomplete="off"
  >
    <a-row :gutter="24">
      <template v-for="(config, index) in configs" :key="index">
        <a-col v-if="config.type === 'rangePicker'" :span="config.span">
          <a-form-item
            class="form-item"
            :label="config.label"
            :name="computedRangePickerKey(config.key)"
            :label-col="config.labelCol"
            :wrapper-col="config.wrapperCol"
            :rules="computedRules(config)"
          >
            <a-range-picker
              class="range-picker"
              :showTime="config.showTime"
              :disabled="disabled || config.disabled"
              :placeholder="[`开始时间`, `结束时间`]"
              :format="config.format || 'YYYY-MM-DD HH:mm:ss'"
              :value-format="config.valueFormat || config.format || 'YYYY-MM-DD HH:mm:ss'"
              v-model:value="formState[computedRangePickerKey(config.key)]"
              @change="() => onChangeCallBack(formState)"
              allowClear
              :disabled-date="config.disabledDate"
            />
          </a-form-item>
        </a-col>

        <a-col v-else-if="config.type === 'cascader'" :span="config.span">
          <a-form-item
            class="form-item"
            :name="computedCascaderKey(config.key)"
            :label="config.label"
            :label-col="config.labelCol"
            :wrapper-col="config.wrapperCol"
            :rules="computedRules(config)"
          >
            <a-cascader
              v-model:value="formState[computedCascaderKey(config.key)]"
              :options="createTree(config.options)"
              @change="() => onChangeCallBack(formState)"
              :placeholder="`请选择${config.label}`"
              allowClear
            />
          </a-form-item>
        </a-col>
        <a-col v-else-if="config.type === 'hidden'" :span="config.span"></a-col>
        <a-col :span="config.span" v-else>
          <a-form-item
            class="form-item"
            :label="config.label"
            :name="computedKey(config.key)"
            :label-col="config.labelCol"
            :wrapper-col="config.wrapperCol"
            :rules="computedRules(config)"
          >
            <template v-if="config.type === 'input'">
              <a-input
                class="input"
                :disabled="disabled || config.disabled"
                :placeholder="`请输入${config.label}`"
                :maxlength="config.maxlength || 100"
                v-model:value="formState[computedKey(config.key)]"
                @change="() => onChangeCallBack(formState)"
                allowClear
              />
            </template>
            <template v-if="config.type === 'password'">
              <a-input-password
                class="input"
                :disabled="disabled || config.disabled"
                :placeholder="`请输入${config.label}`"
                :maxlength="config.maxlength || 20"
                v-model:value="formState[computedKey(config.key)]"
                @change="() => onChangeCallBack(formState)"
                autocomplete="new-password"
                allowClear
              />
            </template>
            <template v-if="config.type === 'inputNumber'">
              <a-input-number
                class="input-number"
                :disabled="disabled || config.disabled"
                :placeholder="`请输入${config.label}`"
                v-model:value="formState[computedKey(config.key)]"
                @change="() => onChangeCallBack(formState)"
                allowClear
              />
            </template>
            <template v-else-if="config.type === 'textarea'">
              <a-textarea
                class="textarea"
                :disabled="disabled || config.disabled"
                :placeholder="`请输入${config.label}`"
                :maxlength="config.maxlength || 200"
                v-model:value="formState[computedKey(config.key)]"
                @change="() => onChangeCallBack(formState)"
                :showCount="config.showCount || false"
                allowClear
                :autoSize="config.autoSize || true"
              />
            </template>
            <template v-else-if="config.type === 'select'">
              <a-select
                class="select"
                :disabled="disabled || config.disabled"
                :placeholder="`请输入${config.label}`"
                :options="computedSelectOptions(config.options, config.selectConfig)"
                v-model:value="formState[computedKey(config.key)]"
                @change="() => onChangeCallBack(formState)"
                allowClear
              />
            </template>

            <template v-else-if="config.type === 'radio'">
              <a-radio-group
                class="radio-group"
                :disabled="disabled || config.disabled"
                :options="computedSelectOptions(config.options, config.selectConfig)"
                v-model:value="formState[computedKey(config.key)]"
                @change="() => onChangeCallBack(formState)"
              />
            </template>
            <template v-else-if="config.type === 'datePicker'">
              <a-date-picker
                class="date-picker"
                :showTime="config.showTime"
                :disabled="disabled || config.disabled"
                :placeholder="`请输入${config.label}`"
                :format="config.format || 'YYYY-MM-DD HH:mm:ss'"
                :value-format="config.valueFormat || config.format || 'YYYY-MM-DD HH:mm:ss'"
                v-model:value="formState[computedKey(config.key)]"
                @change="() => onChangeCallBack(formState)"
                allowClear
              />
            </template>
            <template v-else-if="config.type === 'uploadImgs'">
              <a-input
                v-model:value="formState[computedKey(config.key)]"
                type="hidden"
                @change="() => onChangeCallBack(formState)"
              />
              <section
                class="remote-img-item"
                v-for="(elem, indx) in computedRemoteFiles(formState[computedKey(config.key)])"
                :key="elem"
              >
                <ZgyxUploadImgs
                  :disabled="disabled || config.disabled"
                  :remoteFileIds="elem"
                  @update:remoteFileIds="
                    (val) =>
                      onChangeRemoteFiles(config, computedRemoteFiles(formState[computedKey(config.key)]), indx, val)
                  "
                >
                  <ZgyxRemoteImg class="img" :key="elem" :remoteFileId="elem" />
                </ZgyxUploadImgs>
              </section>
              <section class="remote-img-item" v-if="computedVisibleEmptyUpload(config)">
                <ZgyxUploadImgs
                  :disabled="disabled || config.disabled"
                  @update:remoteFileIds="
                    (val) =>
                      onChangeRemoteFiles(
                        config,
                        computedRemoteFiles(formState[computedKey(config.key)]),
                        computedRemoteFiles(formState[computedKey(config.key)]).length,
                        val
                      )
                  "
                />
              </section>
              <section class="remote-img-tips" v-if="config.tips">
                {{ config.tips ?? '' }}
              </section>
            </template>
            <template v-else-if="config.type === 'uploadFiles'">
              <a-input
                type="hidden"
                v-model:value="formState[computedKey(config.key)]"
                @change="() => onChangeCallBack(formState)"
              />
              <section
                class="remote-file-item"
                v-for="(elem, indx) in computedRemoteFiles(formState[computedKey(config.key)])"
                :key="elem"
              >
                <ZgyxUploadFiles
                  :disabled="disabled || config.disabled"
                  :remoteFileIds="elem"
                  @update:remoteFileIds="
                    (val) =>
                      onChangeRemoteFiles(config, computedRemoteFiles(formState[computedKey(config.key)]), indx, val)
                  "
                >
                  <ZgyxRemoteFile class="link" :remoteFileId="elem" />
                </ZgyxUploadFiles>
              </section>
              <section class="remote-file-item" v-if="computedVisibleEmptyUpload(config)">
                <ZgyxUploadFiles
                  :disabled="disabled || config.disabled"
                  @update:remoteFileIds="
                    (val) =>
                      onChangeRemoteFiles(
                        config,
                        computedRemoteFiles(formState[computedKey(config.key)]),
                        computedRemoteFiles(formState[computedKey(config.key)]).length,
                        val
                      )
                  "
                />
              </section>
            </template>
            <template v-else-if="config.type === 'custom'">
              <slot :name="config.key" :config="config" :formData="formData" :index="index" />
            </template>
          </a-form-item>
        </a-col>
      </template>
    </a-row>
  </a-form>
</template>

<script>
import { ref, watch } from 'vue';
import { set, has, cloneDeep } from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons-vue';
// apis
// utils
import {
  computedKey,
  computedRangePickerKey,
  computedCascaderKey,
  computedRules,
  computedSelectOptions,
  createTree,
  computedRemoteFiles,
  createInnerFormState,
  createOutFormData,
  getObjectValue
} from './utils';
// mixins
// configs
// components
// 严厉禁止使用watch!!!!!!!!!!
import ZgyxRemoteImg from '../zgyxRemoteImg/ZgyxRemoteImg.vue';
import ZgyxRemoteFile from '../zgyxRemoteFile/ZgyxRemoteFile.vue';
import ZgyxUploadImgs from '../zgyxUploadImgs/ZgyxUploadImgs.vue';
import ZgyxUploadFiles from '../zgyxUploadFiles/ZgyxUploadFiles.vue';
export default {
  name: 'ZgyxFormGirdEdit',
  components: { CloseCircleOutlined, ZgyxUploadFiles, ZgyxUploadImgs, ZgyxRemoteFile, ZgyxRemoteImg },
  props: {
    labelCol: { type: Object, default: () => ({ span: 6 }) },
    wrapperCol: { type: Object, default: () => ({ span: 18 }) },
    // 主form表单
    formData: { type: Object, default: () => ({}) },
    // 额外的form
    extendFormData: { type: Object, default: () => ({}) },
    // 配置文件
    configs: { type: Array, default: () => [] },
    // 表单是否禁用
    disabled: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const formRef = ref();
    const formState = ref(createInnerFormState(props.configs, props.formData));

    /**
     * 修改回调
     */
    const onChangeCallBack = (formState) => {
      try {
        const nextFormData = Object.assign({}, props.formData, createOutFormData(props.configs, cloneDeep(formState)));
        emit('update:formData', nextFormData);
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 修改远程文件类型的file
     */
    const onChangeRemoteFiles = async ({ key }, values, indx, val) => {
      try {
        let newValues = cloneDeep(values);
        set(newValues, indx, val);
        formState.value[computedKey(key)] = newValues.filter(Boolean).join();
        formRef.value.validate(key); // 重新校验指定校验项目
        onChangeCallBack(formState.value);
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 计算空的新增组件是否要出现
     */
    const computedVisibleEmptyUpload = ({ disabled, key, maxCount = 1 }) => {
      try {
        if (!has(props.formData, key)) {
          return true;
        }
        if (props.disabled || disabled) {
          return false;
        }
        if (computedRemoteFiles(formState.value[computedKey(key)]).length >= maxCount) {
          return false;
        }
        return true;
      } catch (error) {
        console.error(error);
        return true;
      }
    };
    /**
     * 校验
     */
    const validate = async () => {
      try {
        const { validate: innerValidate } = formRef.value;
        const values = await innerValidate();
        const nextFormData = Object.assign({}, props.formData, createOutFormData(props.configs, cloneDeep(values)));
        return Promise.resolve({ isOk: true, formData: nextFormData });
      } catch (error) {
        console.error(error);
        return Promise.resolve({ isOk: false, formData: {} });
      }
    };

    watch(
      () => props.formData,
      (newVal) => {
        formState.value = createInnerFormState(props.configs, newVal);
      },
      { deep: true, immediate: true }
    );

    return {
      formRef,
      formState,
      computedKey,
      computedRangePickerKey,
      computedCascaderKey,
      computedRules,
      computedSelectOptions,
      createTree,
      computedRemoteFiles,
      computedVisibleEmptyUpload,
      getObjectValue,
      onChangeRemoteFiles,

      onChangeCallBack,
      validate
    };
  },
  methods: {}
};
</script>

<style lang="less" scoped>
@import url('./ZgyxFormGirdEdit.less');
</style>
