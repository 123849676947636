<template>
  <a class="link" target="_blank" v-if="remoteFileUrl" :href="remoteFileUrl">
    {{ remoteFileInfo.fileName }}
  </a>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue';
// apis
import { getFile } from '@src/apis';
// utils
// mixins
// configs
import { regExpIsHttp } from '@src/configs';
// components
export default {
  name: 'ZgyxRemoteImg',
  components: { PlusOutlined },
  props: {
    remoteFileId: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'middle',
      validator(value) {
        return ['large', 'middle', 'small'].includes(value);
      }
    }
  },
  data() {
    return {
      remoteFileInfo: {}
    };
  },
  computed: {
    remoteFileUrl() {
      const _this = this;
      const { size, remoteFileInfo, remoteFileId } = _this;
      const { fileDomain, fileCode } = remoteFileInfo;

      if (regExpIsHttp.test(remoteFileId)) {
        return remoteFileId;
      } else {
        switch (size) {
          case 'large':
            return fileDomain && fileCode ? `${fileDomain}L${fileCode}` : '';
          case 'small':
            return fileDomain && fileCode ? `${fileDomain}S${fileCode}` : '';
          case 'middle':
          default:
            return fileDomain && fileCode ? `${fileDomain}${fileCode}` : '';
        }
      }
    }
  },
  methods: {
    async getMemberSystemGetFile(remoteFileId) {
      try {
        const _this = this;
        if (remoteFileId) {
          const { code, data } = await getFile({ id: remoteFileId });
          if (code === '00000') {
            _this.remoteFileInfo = data;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    const _this = this;
    const { remoteFileId } = _this;
    _this.$nextTick(() => {
      if (!regExpIsHttp.test(remoteFileId)) {
        _this.getMemberSystemGetFile(remoteFileId);
      }
    });
  },
  watch: {
    remoteFileId(newVal) {
      this.getMemberSystemGetFile(newVal);
    }
  }
};
</script>

<style lang="less" scoped></style>
