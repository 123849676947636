import Hashes from 'jshashes';
/**
 * 生成加密的密码
 * @param {*} publicKey
 * @param {*} password
 * @returns
 */
const SHA1 = new Hashes.SHA1();
const createJSEncryptPassword = (password) => {
  try {
    return SHA1.hex(password);
  } catch (error) {
    console.error(error);
  }
};

/**
 * 生成菜单
 * @param {*} locationMenus
 * @param {*} remoteMenuPaths
 * @returns
 */
const createMenuConfigs = (locationMenus, remoteMenuPaths) => {
  return locationMenus
    .filter((locationMenu) => {
      const { children = [] } = locationMenu;
      if (remoteMenuPaths.includes(locationMenu.path)) {
        return true;
      } else {
        if (children.some((child) => remoteMenuPaths.includes(child.path))) {
          return true;
        } else {
          return false;
        }
      }
    })
    .map((locationMenu) => {
      const { children = [] } = locationMenu;
      const nextChildren = createMenuConfigs(children, remoteMenuPaths);
      return {
        ...locationMenu,
        children: nextChildren ? nextChildren : null
      };
    });
};
/**
 * 根据权限生成菜单
 * @param {*} menuConfigs
 * @param {*} userinfo
 * @returns
 */
const createFinnalMenuConfigs = (menuConfigs, userinfo) => {
  const { realNameFlag } = userinfo;
  return menuConfigs
    .map((menuConfig) => {
      const {
        meta: { needAuth = false },
        children = []
      } = menuConfig;
      const _children = createFinnalMenuConfigs(children, userinfo);
      if (needAuth && realNameFlag === 0 && process.env.NODE_ENV !== 'development') {
        return null;
      } else {
        return { ...menuConfig, children: _children };
      }
    })
    .filter(Boolean);
};
export { createJSEncryptPassword, createMenuConfigs, createFinnalMenuConfigs };
