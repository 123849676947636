/**
 * 检查Http
 */
const regExpIsHttp = new RegExp(/(http|https):\/\/([\w.]+\/?)\S*/);

/**
 * 检查密码强度
 */
const regExpCheckPasswordStrength = new RegExp(/(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.[^\u4e00-\u9fa5 ]{5,20}$/);

/**
 * 检查手机号规范性
 */
const regExpCheckPhone = new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/);

/**
 * 检查账号强度
 */
const regExpCheckAccountStrength = new RegExp(/^[a-zA-Z0-9]{4,20}$/);

/**
 * 检查价格
 */
const regExpCheckPriceStrength = new RegExp(/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/);

/**
 * 检查版本号
 */
const regExpCheckVersionStrength = new RegExp(/^\S{1,10}$/);

/**
 * 检查应用名称
 */
const regExpCheckAppNameStrength = new RegExp(/^[\u4E00-\u9FA5A-Za-z0-9]{2,6}$/);

/**
 * 邮箱
 */
const regExpCheckEmail = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export {
  regExpIsHttp,
  regExpCheckPasswordStrength,
  regExpCheckPhone,
  regExpCheckAccountStrength,
  regExpCheckPriceStrength,
  regExpCheckVersionStrength,
  regExpCheckAppNameStrength,
  regExpCheckEmail
};
