<template>
  <div ref="newModal">
    <a-modal
      :getContainer="() => $refs.newModal"
      title="预览"
      visible
      :width="1200"
      @cancel="onCancel"
      @ok="onOk"
      :maskClosable="false"
      :keyboard="false"
      :centered="true"
      :footer="null"
    >
      <a-spin :spinning="spinning" tip="加载中" size="large">
        <section class="layout">
          <div v-if="fileType == 'mp3'">
            <audio controls>
              <source :src="fileUrl" type="audio/mpeg" />
              您的浏览器不支持 audio 元素。
            </audio>
          </div>
          <div v-if="fileType == 'mp4'">
            <video width="1200" height="675" controls>
              <source :src="fileUrl" type="video/mp4" />
              您的浏览器不支持 video 标签。
            </video>
          </div>
        </section>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    fileUrl: {
      type: [String, Number],
      default: ''
    },
    fileType: {
      type: [String, Number],
      default: 'mp3'
    }
  },
  data() {
    return {
      spinning: false
    };
  },
  computed: {},
  async created() {
    const _this = this;
  },
  mounted() {
    const _this = this;
  },
  methods: {
    /**
     * 点击确定
     */
    async onOk() {
      try {
        const _this = this;
        _this.$emit('onOk');
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击取消
     */
    onCancel() {
      try {
        const _this = this;
        _this.$emit('onCancel');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  height: 80vh;
  padding: 0;
  overflow-y: auto;
}
.docWrap {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
