<template>
  <div ref="newModal">
    <a-modal
      :getContainer="() => $refs.newModal"
      title="账号信息"
      visible
      :width="1000"
      @cancel="onCancel"
      @ok="onOk"
      :maskClosable="false"
      :keyboard="false"
      :ok-button-props="{ disabled: isNotAccountGrade }"
    >
      <a-spin :spinning="spinning" tip="加载中" size="large">
        <section class="layout">
          <ZgyxFormGirdEdit
            ref="girdFormEdit"
            :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18 }"
            :formData="formState"
            :configs="[
              {
                span: 24,
                type: 'input',
                label: '申报单位名称',
                key: 'orgName',
                rules: [{ required: true }],
                disabled: isNotAccountGrade
              },
              {
                span: 24,
                type: 'input',
                label: '单位负责人',
                key: 'orgPrincipal',
                rules: [{ required: true }],
                disabled: isNotAccountGrade
              },
              {
                span: 24,
                type: 'input',
                label: '手机号码',
                key: 'orgMobile',
                rules: [
                  { required: true, message: '请输入联系电话' },
                  { pattern: regExpCheckPhone, message: '手机号格式不正确' }
                ],
                disabled: isNotAccountGrade
              },
              {
                span: 24,
                type: 'input',
                label: '传真',
                key: 'orgFax',
                rules: [{ required: false }],
                disabled: isNotAccountGrade
              }
              /*{
                span: 24,
                type: 'input',
                label: '电子邮箱',
                key: 'orgEmail',
                rules: [
                  { required: false, message: '请输入电子邮箱' },
                  { pattern: regExpCheckEmail, message: '邮箱格式不正确' }
                ],
                disabled: isNotAccountGrade
              },
              {
                span: 24,
                type: 'textarea',
                label: '详细地址',
                key: 'orgAddress',
                showCount: true,
                maxlength: 200,
                autoSize: { minRows: 6 },
                rules: [{ required: false }],
                disabled: isNotAccountGrade
              },
              {
                span: 24,
                type: 'textarea',
                label: '账号信息',
                key: 'introduce',
                showCount: true,
                maxlength: 2000,
                autoSize: { minRows: 6 },
                rules: [{ required: false }],
                disabled: isNotAccountGrade
              }*/
            ]"
            @update:formData="(formData) => (formState = formData)"
          >
          </ZgyxFormGirdEdit>
        </section>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { ZgyxFormGirdEdit } from '@src/components';
import { apiGetUserInfo, apiPostSaveUserInfo } from '@src/apis';
import { regExpCheckPhone, regExpCheckEmail } from '@src/configs';
export default {
  name: '',
  components: { ZgyxFormGirdEdit },
  props: {},
  data() {
    const formState = {
      orgName: '',
      orgPrincipal: '',
      orgMobile: '',
      orgFax: '',
      orgEmail: '',
      orgAddress: '',
      introduce: ''
    };
    return {
      formState,
      spinning: false
    };
  },
  computed: {
    ...mapGetters(['dictionaries', 'userinfo']),
    // 手机号格式
    regExpCheckPhone() {
      return regExpCheckPhone;
    },
    regExpCheckEmail() {
      return regExpCheckEmail;
    },
    isNotAccountGrade() {
      return this.userinfo.accountGrade == 'main' ? false : true;
    }
  },
  created() {},
  mounted() {
    const _this = this;
    _this.$nextTick(async () => {
      _this.handleGetInfo();
    });
  },
  methods: {
    /*
     *获取详情
     */
    async handleGetInfo() {
      try {
        const _this = this;
        const { code, data } = await apiGetUserInfo(_this.userinfo.id);

        if (code === '00000' && data) {
          _this.formState = Object.assign({}, _this.formState, data ?? {});
        }
      } catch (error) {
        console.error(error);
      }
    },
    /*
     * 更新
     */
    async handleUpdate() {
      try {
        const _this = this;
        const { formState } = this;
        let sendData = Object.assign({}, formState);
        const { code, msg } = await apiPostSaveUserInfo(sendData);
        if (code === '00000') {
          message.success(msg ?? '成功');
          _this.$emit('onOk');
        } else {
          // message.error(msg ?? '失败');
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击确定
     */
    async onOk() {
      try {
        const _this = this;
        const { isOk } = await _this.$refs.girdFormEdit.validate();
        if (isOk) {
          _this.handleUpdate();
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击取消
     */
    onCancel() {
      try {
        const _this = this;
        _this.$emit('onCancel');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  height: 60vh;
  padding: 20px;
  overflow-y: auto;
}
</style>
