const downloadFile = async (blob, type, fileName, callback) => {
  try {
    const url = window.URL.createObjectURL(new Blob([blob], { type }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
    callback && callback();
  } catch (error) {
    console.error(error);
  }
};

const downloadFile2 = async (e, callback) => {
  try {
    const x = new window.XMLHttpRequest();
    x.open('GET', e.url, true);
    x.responseType = 'blob';
    x.onload = () => {
      const url = window.URL.createObjectURL(x.response);
      const a = document.createElement('a');
      a.href = url;
      a.download = e.fileName;
      a.click();
      callback && callback();
    };
    x.send();
  } catch (error) {
    console.error(error);
  }
};

const isJSON = (str) => {
  let defaultValue = false;
  if (typeof str != 'string') {
    // 1、传入值必须是 字符串
    defaultValue = false;
  }

  try {
    var obj = JSON.parse(str); // 2、仅仅通过 JSON.parse(str)，不能完全检验一个字符串是JSON格式的字符串
    if (typeof obj == 'object' && obj) {
      //3、还必须是 object 类型
      defaultValue = true;
    } else {
      defaultValue = false;
    }
  } catch (e) {
    defaultValue = false;
  }
  return defaultValue;
};

export { downloadFile, downloadFile2, isJSON };
